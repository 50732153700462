import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../utils/colors"
import { outerStyles, innerStyles, mobileWidth } from "../utils/common-styles"
import dev from "../images/svg/dev.svg"

const Container = styled.header`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mobileWidth}px) {
    align-items: center;
    height: 80px;
  }
`

const HomePageLink = styled(Link)`
  color: ${colors.WHITE};
  text-decoration: none;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding: 5px 0;
  @media (max-width: ${mobileWidth}px) {
    font-size: 18px;
  }
`

const DevIcon = styled.img`
  margin: -5px 5px -12px 0;
`

const HeaderQuiz = () => (
  <Container>
    <Inner>
      <HomePageLink to="/">
        <DevIcon src={dev} alt="" />
        <span>Softwareentwicklerfinden.de</span>
      </HomePageLink>
    </Inner>
  </Container>
)

export default HeaderQuiz
