import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Loader from "./loader"
import HeaderQuiz from "./header-quiz"
import SEO from "./seo"
import JSONData from "../constants/sitesScore.json"
import siteJSON from "../constants/sites.json"
import {
  Title,
  PostText,
  outerStyles,
  innerStyles,
} from "../utils/common-styles"
import colors from "../utils/colors"
import GlobalStyles from "./global-styles.js"

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 80px);
`

const QuizTitle = styled.h3`
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.BLACK_TEXT};
`

const QuizResult = styled(PostText)`
  color: ${colors.BLACK_TEXT};
`

const CustomTitle = styled(Title)`
  color: ${colors.BLACK_TEXT};
`

const Container = styled.section`
  ${outerStyles}
  background: ${props => props.bgColor};
`

const ResultItem = styled.div`
  color: ${colors.BLACK_TEXT};
`

const ResultList = ({ answers }) => {
  const [sites, setSites] = useState([])
  const [loader, setLoader] = useState(true)
  const scoreArray = JSONData.answers

  const hydrate = useCallback(
    answers => {
      const asnswerScores = answers.map(
        i => scoreArray[i.question.number][i.answer.number - 1]
      )

      const sumValueByKey = (...rest) =>
        rest.reduce((result, current) => {
          for (let key in current) {
            const value = current[key]
            result[key] =
              result[key] === undefined ? value : result[key] + value
          }
          return result
        }, {})

      const sortByScore = () => {
        const data = sumValueByKey(...asnswerScores)

        setSites(
          Object.assign(
            [],
            Object.entries(data)
              .map(([key, val]) => ({ val, key, text: siteJSON[key] }))
              .sort((a, b) => b.val - a.val)
          )
        )
      }

      sortByScore()
    },
    [scoreArray]
  )

  useEffect(() => {
    hydrate(answers)

    return () => {
      setSites([])
    }
  }, [hydrate, answers])

  return (
    <>
      <GlobalStyles />
      <HeaderQuiz />
      <SEO title="Thank you" />
      <Container bgColor={loader ? colors.BLUE : colors.WHITE}>
        <Inner>
          {loader ? (
            <Loader onLoad={setLoader} />
          ) : (
            <>
              <ResultItem>
                <CustomTitle>Ditt resultat</CustomTitle>
              </ResultItem>
              {sites.length &&
                sites.map((item, index) => (
                  <ResultItem key={index}>
                    <QuizTitle>{`${index + 1}. ${item.key}`}</QuizTitle>
                    <QuizResult>
                      {item.text.map((i, id) => (
                        <p key={index + id}>{i}</p>
                      ))}
                    </QuizResult>
                  </ResultItem>
                ))}
            </>
          )}
        </Inner>
      </Container>
    </>
  )
}

ResultList.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

export default ResultList
