import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Title, Subtitle } from "../utils/common-styles"

const SubtitleLoader = styled(Subtitle)`
  font-weight: 500;
  font-size: 25px;
  margin-top: 25px;
`

const Loader = ({ onLoad }) => {
  const [count, setCount] = useState(0)
  const sites = ["Linkedin jobs",  "Wopify", "Demando", "Upwork", "Stackoverflow", "Academic work", "Freelancer.com", "Toptal", "HiQ"]

  useEffect(() => {
    count === sites.length && onLoad(false)
    const timer = setTimeout(() => setCount(count + 1), 450)
    return () => clearTimeout(timer)
  }, [count, onLoad, sites.length])

  return (
    <>
      <Title>Finding the best site based on your needs.</Title>
      <SubtitleLoader>{sites[count]}</SubtitleLoader>
    </>
  )
}

Loader.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Loader
