import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "typeface-muli"

import HeaderQuiz from "./header-quiz"
import GlobalStyles from "./global-styles.js"
import { outerStyles, innerStyles } from "../utils/common-styles"
import colors from "../utils/colors"

const Container = styled.section`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  height: calc(100vh - 80px);
`

const LayoutQuiz = ({ children }) => (
  <>
    <HeaderQuiz />
    <Container>
      <Inner>{children}</Inner>
    </Container>
    <GlobalStyles />
  </>
)

LayoutQuiz.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutQuiz
