import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import colors from "../utils/colors"
import { Subtitle } from "../utils/common-styles"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`

const Title = styled(Subtitle)`
  margin: 0;
`

const Dots = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1vh 0;
  ${({ total }) => css`
    width: ${total * 3}vw;
    min-width: ${total * 20}px;
    max-width: 90vw;
  `}
`

const Dot = styled.li`
  width: 11px;
  height: 11px;
  background: ${colors.GREEN};
  margin: 0;
  border-radius: 100%;
  ${({ answered }) => !answered && css`
    width: 9px;
    height: 9px;
    opacity: 0.4;
  `}
`

const Line = styled.div`
  background: ${colors.GREEN};
  position: absolute;
  top: 5px;
  left: 0;
  height: 1px;
  ${({ ratio }) => css`
    width: ${ratio}%;
  `}
`

const DotsList = ({ total, current }) => [...Array(total).keys()].map((n) => (
  <Dot key={n} answered={n < current} />
))

const Progress = ({ total, current }) => (
  <Container>
    <Dots total={total}>
      <DotsList total={total} current={current} />
    </Dots>
    <Line ratio={100 * (current - 1) / (total - 1)} />
    <Title>{ `${current}/${total}` }</Title>
  </Container>
)

Progress.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}

export default Progress
